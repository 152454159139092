import axios from "axios";

const API = axios.create(
    // {baseURL: 'http://127.0.0.1:8000/api'}
    {baseURL: 'https://backend.xmingeradvertising.com/api'}
    
);
export const fetchMessages = () => API.get('/messages');

export const postMessages = (userMessage) => API.post('/messages/add', userMessage);
export const getBlogs = () => API.get('/blog/view');