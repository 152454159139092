import React from 'react'
// import React, {useEffect, useState} from 'react'
// import { fetchMessages } from '../api'
// import { Link } from 'react-router-dom'
import imgs from "../images/img4.jpg"
import about_image1 from "../images/img1.webp"
import about_image3 from "../images/site.webp"
import about_image4 from "../images/site_banner.webp"
import about_image2 from '../images/banner4.webp'


function About() {
  // const [mymessage, setMyMessage] = useState([]);
  // useEffect(()=> {
  //   fetchMessages()
  //   .then(response => setMyMessage(response.data))
  //   .catch(error => console.error(error));
  // },[]);
  return (
    <>
    <div className='' id='about'>
      
    <div className="row" id='about-txt'>
  
         <div className="col-12">
          <img className='about-img' src={about_image3} alt="" />
          <img className='about-img' src={about_image4} alt="" />
         </div>
         <div className="row">
        <div className="col-12  mb-5">
         </div>
        </div>
      </div>
     <div className="container">
        </div> 
        <div className="container">
      <div className="row">
        <div className="col-md-4">
          <div className="card hover:opacity-80" data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine">
            <div className="card-body">
              <img className='abuot-img' src={imgs} alt="" />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card hover:opacity-80" data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine">
            <div className="card-body">
              <img className='abuot-img' src={about_image2} alt="" />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card hover:opacity-80" data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine">
            <div className="card-body">
              <img className='abuot-img' src={about_image1} alt="" />
            </div>
          </div>
        </div>

       {/*   <div>
        <Link to="about_us" className='py-6 px-3 inline-block'>About Us</Link>  
          <h2>Message</h2>
          <ul>
            {
              mymessage.map(message => (
                <li key={message.id}>{message.fullname}</li>
                // <li key={message.id}>{message.p}</li>
              ))
            }
          </ul>
        </div>*/}
      </div>
     </div>
    </div>
    

    </>
  )
}

export default About